import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CRow,
  CCol,
  CModalFooter,
  CModalBody,
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";

const LogoutButton = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const doLogout = () => {
    setShowLogoutModal(false);
    dispatch({ type: "logout" });
    navigate("/", { replace: true });
  };

  return (
    <>
      <li
        onClick={() => setShowLogoutModal(true)}
        className="logout-button text-start"
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.04583 0.5H9.42083V8.75H8.04583V0.5ZM5.98333 0.972656V2.47656C4.75156 2.99219 3.74896 3.82292 2.97552 4.96875C2.23073 6.11458 1.85833 7.375 1.85833 8.75C1.85833 10.6406 2.53151 12.2591 3.87786 13.6055C5.22422 14.9518 6.84271 15.625 8.73333 15.625C10.624 15.625 12.2424 14.9518 13.5888 13.6055C14.9352 12.2591 15.6083 10.6406 15.6083 8.75C15.6083 7.375 15.2216 6.11458 14.4482 4.96875C13.7034 3.82292 12.7151 2.99219 11.4833 2.47656V0.972656C13.1161 1.54557 14.4339 2.54818 15.4365 3.98047C16.4677 5.38411 16.9833 6.97396 16.9833 8.75C16.9833 11.013 16.1669 12.9609 14.5341 14.5938C12.9299 16.1979 10.9964 17 8.73333 17C6.47031 17 4.5224 16.1979 2.88958 14.5938C1.28542 12.9609 0.483334 11.013 0.483334 8.75C0.483334 6.97396 0.984636 5.38411 1.98724 3.98047C3.01849 2.54818 4.35052 1.54557 5.98333 0.972656Z"
            fill="#E6192E"
          />
        </svg>{" "}
        <span className="text-danger">Logout</span>
      </li>
      <CModal
        className="modal-custom modal-custom-logout"
        scrollable
        alignment="center"
        size="lg"
        visible={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        aria-labelledby="ScrollingLongContentExampleLabel2"
      >
        <CModalBody>
          <CRow>
            <CCol className="mb-4 logout-content">
              <h3>Logout</h3>
              <span>Are you sure you want to Logout?</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol className="btns-container">
              <CButton
                className="cancel-btn"
                onClick={() => setShowLogoutModal(false)}
              >
                No
              </CButton>
              <CButton onClick={doLogout} className="logout-btn">
                Yes
              </CButton>
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
    </>
  );
};

export default LogoutButton;
