import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { CBadge } from "@coreui/react";
import { useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilDrop } from "@coreui/icons";

export const AppSidebarNav = ({ items }) => {
  const user = useSelector((state) => state.user);
  const messageCount = useSelector((state) => state.messageCount);
  const location = useLocation();

  const importAllIcons = (r) => {
    let icons = {};
    r.keys().forEach((item) => {
      icons[item.replace('./', '').replace('.svg', '')] = r(item);
    });
    return icons;
  };
  const Icons = importAllIcons(require.context('../../assets/sidebar-icons', false, /\.svg$/));

  const navLink = (name, icon, badge) => {
    const IconComponent = Icons[name] || null;
    const isSelected = location.pathname.includes(name.toLowerCase());




    return (
      <>
        {IconComponent ? (
          <span
            className="nav-icon"
            style={{
              display: "inline-block",
              width: "1em",
              height: "1em",
              marginRight: "10px",
              marginLeft: "-10px",
            }}
          >
            <img
              src={IconComponent}
              alt={`${name} icon`}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </span>
        ) : (
          <CIcon icon={cilDrop} style={{ fill: isSelected ? "#FFFFFF" : "#000000" }} customClassName="nav-icon" />
        )}
        {name && <span>{name}</span>}
        {user.role.type === "ADMIN" && messageCount && messageCount[name] > 0 && (
          <CBadge color="danger">{messageCount[name]}</CBadge>
        )}
        {badge && badge.text > 0 && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };

  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item;
    const Component = component;

    return (
      <Component
        {...(rest.to && !rest.items && { component: NavLink })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    );
  };

  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item;
    const Component = component;
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((childItem, childIndex) =>
          childItem.items ? navGroup(childItem, childIndex) : navItem(childItem, childIndex)
        )}
      </Component>
    );
  };

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
    </React.Fragment>
  );
};

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
