import { createStore } from 'redux'
import { messaging } from 'src/firebase'

const initialState = {
  sidebarShow: false,
  notificationCount: 0,
  showNotificationPopup: false,
  ScreenShareList: [],
  UserInterected: false
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      if (rest.hasOwnProperty('PeerInstance')) {
        if (!rest.PeerInstance && state.PeerInstance) {
          rest['ScreenShareList'] = []
          state.PeerInstance.destroy()
        }
      }
      return { ...state, ...rest }

    case 'login':
      localStorage.setItem('token', rest.user.token)
      localStorage.setItem('user', JSON.stringify(rest.user))
      return { ...state, ...rest }

    case 'logout':
      localStorage.clear()
      messaging.deleteToken().catch(_ => { })
      return { ...state, user: null }
  
    case 'chat_count_set':
      let chatCountFromLS = localStorage.getItem('chat_' + rest.chat.id)
      let count = rest.chat.count;
      if (chatCountFromLS !== null) {
        if (rest.chat.inc)
          count = parseInt(chatCountFromLS) + 1;
      }
      localStorage.setItem('chat_' + rest.chat.id, count)
      return { ...state, ...rest }

    case 'chat_count_remove':
      localStorage.removeItem('chat_' + rest.chat.id)
      return { ...state, chatCount: 0 }

    case 'chat_count_get':
      let data = localStorage.getItem('chat_' + rest.chat.id)
      data = data === null ? 0 : data;
      return { ...state, chatCount: parseInt(data) }

    case 'ScreenShare':
      let ScreenShareList = []
      switch (rest.action) {
        case 'add':
          let isFirstVideo = rest.value.kind === 'video' && state.ScreenShareList.every(l => l.kind == 'audio')
          rest.value['pin'] = isFirstVideo
          ScreenShareList = [...state.ScreenShareList, rest.value]
          return { ...state, ScreenShareList }

        case 'remove':
          let isPinnedVideoRemoved = false
          ScreenShareList = state.ScreenShareList.filter(list => {
            let shouldRemove = rest.value.participantId
              ? list.participantId === rest.value.participantId
              : list.producerId === rest.value.producerId;

            if (shouldRemove) {
              isPinnedVideoRemoved = (list.kind == 'video' && list.pin) ? true : false
              list.stream.getTracks().forEach(track => track.stop())
              return false
            } else {
              return true
            }
          })
          if (isPinnedVideoRemoved) {
            let firstVideoRef = ScreenShareList.find(l => l.kind == 'video')
            if (firstVideoRef) {
              ScreenShareList = state.ScreenShareList.map(list => {
                list['pin'] = list.producerId === firstVideoRef.producerId
                return list
              })
              return {
                ...state,
                ScreenShareList,
                pinScreen: { producerId: firstVideoRef.producerId }
              }
            }
          }
          return { ...state, ScreenShareList }

        case 'removeAll':
          state.ScreenShareList.forEach(list => {
            list.stream.getTracks().forEach(track => track.stop())
          })
          return { ...state, ScreenShareList: [] }

        case 'pin':
          ScreenShareList = state.ScreenShareList.map(list => {
            list['pin'] = list.producerId === rest.value.producerId
            return list
          })
          return {
            ...state,
            ScreenShareList,
            pinScreen: { producerId: rest.value.producerId }
          }

        case 'speaker':
          ScreenShareList = state.ScreenShareList.map(list => {
            list.muted = rest.value.muted
            return list
          })
          return { ...state, ScreenShareList: ScreenShareList }

        case 'mic':
          ScreenShareList = state.ScreenShareList.map(list => {
            if (list.participantId === rest.value.participantId) {
              list.mic = rest.value.mic
            }
            return list
          })
          return { ...state, ScreenShareList: ScreenShareList }

        case 'label':
          ScreenShareList = state.ScreenShareList.map(list => {
            if (list.producerId === rest.value.producerId) {
              list.label = rest.value.label
            }
            return list
          })
          return { ...state, ScreenShareList: ScreenShareList }

        default:
          return state
      }

    default:
      return state
  }
}

const store = createStore(changeState)
export default store
