import React from "react";
import CIcon from "@coreui/icons-react";
import { cilDrop } from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react";

export const adminNavigation = [
  {
    component: CNavTitle,
    name: "MENU",
    is_sidebar: "true",
  },
  {
    component: CNavItem,
    name: 'BOTs',
    to: '/bots',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#000000"
      >
        <path d="M12 14V16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM21 17H22V22H14V17H15V16C15 14.3431 16.3431 13 18 13C19.6569 13 21 14.3431 21 16V17ZM19 17V16C19 15.4477 18.5523 15 18 15C17.4477 15 17 15.4477 17 16V17H19Z"></path>
      </svg>
    )
  },
  {
    component: CNavItem,
    name: "Admin",
    to: "/admin/finance",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#000000"
      >
        <path d="M12 14V16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM21 17H22V22H14V17H15V16C15 14.3431 16.3431 13 18 13C19.6569 13 21 14.3431 21 16V17ZM19 17V16C19 15.4477 18.5523 15 18 15C17.4477 15 17 15.4477 17 16V17H19Z"></path>
      </svg>
    ),
  },

  {
    component: CNavItem,
    name: "Alert History",
    to: "/admin/alerts",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#000000"
      >
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12H4C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C9.25022 4 6.82447 5.38734 5.38451 7.50024L8 7.5V9.5H2V3.5H4L3.99989 5.99918C5.82434 3.57075 8.72873 2 12 2ZM13 7L12.9998 11.585L16.2426 14.8284L14.8284 16.2426L10.9998 12.413L11 7H13Z"></path>
      </svg>
    ),
  },
  {
    component: CNavItem,
    name: "Profile",
    to: "/profile",
    is_dropdown: "true",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#000000"
      >
        <path d="M21.0082 3C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082ZM20 5H4V19H20V5ZM18 15V17H6V15H18ZM12 7V13H6V7H12ZM18 11V13H14V11H18ZM10 9H8V11H10V9ZM18 7V9H14V7H18Z"></path>
      </svg>
    ),
  },
];

export const userNavigation = [
  {
    component: CNavTitle,
    name: "MENU",
    is_sidebar: "true",
  },
  {
    component: CNavItem,
    name: 'BOTs',
    to: '/bots',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#000000"
      >
        <path d="M12 14V16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM21 17H22V22H14V17H15V16C15 14.3431 16.3431 13 18 13C19.6569 13 21 14.3431 21 16V17ZM19 17V16C19 15.4477 18.5523 15 18 15C17.4477 15 17 15.4477 17 16V17H19Z"></path>
      </svg>)
  },
  {
    component: CNavItem,
    name: "Products",
    to: "/products",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.37996 15.5563C5.22678 15.5563 4.29199 16.4912 4.29199 17.6443C4.29199 18.7975 5.22682 19.7323 6.37996 19.7323C7.53313 19.7323 8.46792 18.7974 8.46792 17.6443C8.46796 16.4912 7.53313 15.5563 6.37996 15.5563ZM6.37996 18.8043C5.73931 18.8043 5.21999 18.285 5.21999 17.6443C5.21999 17.0037 5.73931 16.4844 6.37996 16.4844C7.0206 16.4844 7.53993 17.0037 7.53993 17.6443C7.53997 18.285 7.0206 18.8043 6.37996 18.8043Z"
          fill="#202224"
        />
        <path
          d="M15.1959 15.5563C14.0427 15.5563 13.1079 16.4912 13.1079 17.6443C13.1079 18.7975 14.0427 19.7323 15.1959 19.7323C16.349 19.7323 17.2838 18.7974 17.2838 17.6443C17.2838 16.4912 16.3491 15.5563 15.1959 15.5563ZM15.1959 18.8043C14.5552 18.8043 14.0359 18.285 14.0359 17.6443C14.0359 17.0037 14.5552 16.4844 15.1959 16.4844C15.8365 16.4844 16.3558 17.0037 16.3558 17.6443C16.3559 18.285 15.8365 18.8043 15.1959 18.8043Z"
          fill="#202224"
        />
        <path
          d="M19.9054 3.37648C19.8088 3.27093 19.6766 3.20485 19.5342 3.19086L4.43117 2.98207L4.01358 1.70606C3.71939 0.853072 2.92062 0.277217 2.01838 0.26767H0.463997C0.207731 0.26767 0 0.4754 0 0.731667C0 0.987933 0.207731 1.19566 0.463997 1.19566H2.01838C2.52287 1.20681 2.96711 1.53075 3.13199 2.00767L6.07836 10.8932L5.84639 11.4268C5.58763 12.094 5.66534 12.845 6.05518 13.4452C6.44129 14.0346 7.09086 14.3984 7.79518 14.4195H16.8199C17.0762 14.4195 17.2839 14.2118 17.2839 13.9556C17.2839 13.6993 17.0762 13.4916 16.8199 13.4916H7.79514C7.3974 13.4816 7.03127 13.2724 6.82074 12.9348C6.61257 12.6013 6.5698 12.1904 6.70475 11.8211L6.89037 11.4036L16.6575 10.3827C17.7303 10.2646 18.6129 9.48428 18.8615 8.43395L19.9751 3.7708C20.0253 3.63652 19.9986 3.4855 19.9054 3.37648ZM17.9567 8.2252C17.806 8.90132 17.232 9.40005 16.5415 9.4548L6.89037 10.4524L4.73278 3.91006L18.9542 4.11886L17.9567 8.2252Z"
          fill="#202224"
        />
      </svg>
    ),
  },
  {
    component: CNavItem,
    name: "Profile",
    to: "/profile",
    is_dropdown: "true",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#000000"
      >
        <path d="M21.0082 3C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082ZM20 5H4V19H20V5ZM18 15V17H6V15H18ZM12 7V13H6V7H12ZM18 11V13H14V11H18ZM10 9H8V11H10V9ZM18 7V9H14V7H18Z"></path>
      </svg>
    ),
  },
  {
    component: CNavItem,
    name: "Support",
    to: "/support",
    is_dropdown: "true",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#000000"
      >
        <path d="M22 17.0022C21.999 19.8731 19.9816 22.2726 17.2872 22.8616L16.6492 20.9476C17.8532 20.7511 18.8765 20.0171 19.4649 19H17C15.8954 19 15 18.1046 15 17V13C15 11.8954 15.8954 11 17 11H19.9381C19.446 7.05369 16.0796 4 12 4C7.92038 4 4.55399 7.05369 4.06189 11H7C8.10457 11 9 11.8954 9 13V17C9 18.1046 8.10457 19 7 19H4C2.89543 19 2 18.1046 2 17V12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12V12.9987V13V17V17.0013V17.0022ZM20 17V13H17V17H20ZM4 13V17H7V13H4Z"></path>
      </svg>
    ),
  },
];
