import React, { useEffect, useRef, useState } from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";

import { AppSidebarNav } from "./nav";
import BrandLogo from "src/assets/icons/dashboard-logo.png";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { adminNavigation, userNavigation } from "../../_nav";
import LogoutButton from "./logoutButton";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import { CNavItem } from "@coreui/react";
import axios from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";
import { cilMenu } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const AppSidebar = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const location = useLocation();
  const currentUrl = location.pathname
  const unreadCount = useSelector((state) => state.unreadCount);
  const [navigationItems, setNavigationItems] = useState([]);
  const [userChannelsList, setUserChannelsList] = useState([]);

  useEffect(() => {
    if (user) {
      fetchChannels();
    }
  }, [user]);

  useEffect(() => {
    if (unreadCount) {
      setNavigationItems((prev) => {
        prev = prev.map((item) => {
          if (item._id) {
            let refData = unreadCount.find((ch) => ch._id == item._id);
            if (refData) {
              item["badge"] = {
                text: refData.count,
              };
            }
          }
          return item;
        });
        return prev;
      });
    }
  }, [unreadCount]);

  // useEffect(() => {
  //   const botsIndex = navigationItems?.findIndex(item => item.name.toLowerCase() === "bots");
  //   if (botsIndex !== -1 && botsIndex != 2) {
  //     const updatedItems = [...navigationItems];
  //     const [botsItem] = updatedItems.splice(botsIndex, 1);
  //     updatedItems.splice(2, 0, botsItem);
  //     setNavigationItems(updatedItems);
  //   }
  // }, [navigationItems]);

  const fetchChannels = (url) => {
    axios
      .get("/channel/channels-list")
      .then((res) => {
        if (res.data.status) {
          let navigationItems = [];
          let channels = res?.data?.data;
          setUserChannelsList(channels);

          if (
            user?.role?.type == "SUPER_ADMIN" ||
            user?.role?.type == "ADMIN"
          ) {
            for (let index = 0; index < channels.length; index++) {
              navigationItems.push({
                component: CNavItem,
                name: channels[index].name,
                to: "/admin/dashboard/" + channels[index]._id,
              });
            }
          } else {
            for (let index = 0; index < channels.length; index++) {
              if (unreadCount === undefined) {
                navigationItems.push({
                  component: CNavItem,
                  name: channels[index].name,
                  // badge : {text : unreadCount[index1].count},
                  to: "/dashboard/" + channels[index]._id,
                });
              } else {
                for (let index1 = 0; index1 < unreadCount.length; index1++) {
                  if (channels[index]._id === unreadCount[index1]._id) {
                    navigationItems.push({
                      component: CNavItem,
                      name: channels[index].name,
                      badge: { text: unreadCount[index1].count },
                      to: "/dashboard/" + channels[index]._id,
                    });
                  }
                }
              }
            }
          }

          if (
            user?.role?.type == "SUPER_ADMIN" ||
            user?.role?.type == "ADMIN"
          ) {
            navigationItems = [...navigationItems, ...adminNavigation];
          } else {
            navigationItems = [...navigationItems, ...userNavigation];
          }

          if (user.anonymous) {
            navigationItems = navigationItems.filter(
              (item) => item.name !== "Profile"
            );
          }

          setNavigationItems(navigationItems);
        } else {
          // console.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error("Error fetching channels:", err);
      });
  };

  const navigateToHome = () => {
    for (let index = 0; index < userChannelsList.length; index++) {
      if (user?.role?.type == "SUPER_ADMIN") {
        navigate("/admin/dashboard/" + userChannelsList[index]._id, {
          replace: true,
        });
        break;
      } else {
        if (userChannelsList[index].subscribed) {
          navigate("/dashboard/" + userChannelsList[index]._id, {
            replace: true,
          });
          break;
        }
      }
    }

    if (user?.role?.type == "SUPER_ADMIN")
      navigate("/admin/dashboard/" + userChannelsList[0]._id, {
        replace: true,
      });
    else navigate("/dashboard/" + userChannelsList[0]._id, { replace: true });
  };

  if (user === undefined || user === null) return null;
  else
    return (
  currentUrl != '/' &&
      <CSidebar
        className={`sidebar_custom ${unfoldable ? "sidebar-collapsed" : ""}`}
        position="fixed"
        unfoldable={unfoldable}
        visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch({ type: "set", sidebarShow: visible });
        }}
      >
        {/* <button
          className="navbar-toggler sidebar-toggler hide-menu-toggle-items"
          type="button"
          data-toggle="sidebar-show"
          onClick={() =>
            dispatch({ type: "set", sidebarUnfoldable: !unfoldable })
          }
        >
          <CIcon icon={cilMenu} size="lg" style={{ color: "#000" }} />
        </button> */}

        <CSidebarBrand className=" d-md-flex">
          <img
            className="logo-img mob-logo"
            onClick={() => navigateToHome()}
            src={BrandLogo}
            height={30}
          />
          <button
            className="navbar-toggler sidebar-toggler hide-menu-toggle-items d-flex align-items-center gap-3 tab"
            type="button"
            data-toggle="sidebar-show"
            onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
          >
            <svg
              width="38"
              height="39"
              viewBox="0 0 38 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.75 9.75C4.75 8.85255 5.45889 8.125 6.33333 8.125H31.6667C32.5411 8.125 33.25 8.85255 33.25 9.75C33.25 10.6475 32.5411 11.375 31.6667 11.375H6.33333C5.45889 11.375 4.75 10.6475 4.75 9.75ZM4.75 19.5C4.75 18.6025 5.45889 17.875 6.33333 17.875H22.1667C23.0411 17.875 23.75 18.6025 23.75 19.5C23.75 20.3975 23.0411 21.125 22.1667 21.125H6.33333C5.45889 21.125 4.75 20.3975 4.75 19.5ZM4.75 29.25C4.75 28.3525 5.45889 27.625 6.33333 27.625H14.25C15.1244 27.625 15.8333 28.3525 15.8333 29.25C15.8333 30.1475 15.1244 30.875 14.25 30.875H6.33333C5.45889 30.875 4.75 30.1475 4.75 29.25Z"
                fill="black"
              />
            </svg>
            <img
              className="logo-img"
              onClick={() => navigateToHome()}
              src={BrandLogo}
              height={30}
            />
          </button>
        </CSidebarBrand>

        <CSidebarNav>
          <SimpleBar>
            {/* Render items based on the sidebar state */}
            <AppSidebarNav
              items={navigationItems}
              className={unfoldable ? "nav-icons-only" : ""} // Apply class based on the sidebar state
            />
            <LogoutButton />
          </SimpleBar>
        </CSidebarNav>

        {/* Sidebar Toggler to control the state */}
      </CSidebar>
    );
};

export default React.memo(AppSidebar);
